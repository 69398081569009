import React from "react";

export default function WelcomeMessage() {
  return (
    <div className="card mb-4">
      <h2 className="card-header h5">Welcome</h2>
      <div className="card-body">
        <p>
          This wiki is a work in progress, we are working hard to collect all
          data on every item in game. In the mean time, please browse the items
          we have records for.
        </p>
      </div>
    </div>
  );
}
