import React from "react";
import Helmet from "react-helmet";
import { graphql, Link, navigate } from "gatsby";
import styled from "styled-components";

// Utils
// import { getItemSlug } from "../utils/item";

// State
import { connect } from "react-redux";

// Components
import WelcomeMessage from "../components/WelcomeMessage";
import ServerTimes from "../components/ServerTimes";
import CategoryNavigation from "../components/CategoryNavigation";
import GearRow from "../components/GearRow";
// import ItemIcon from "../components/item/ItemIcon";

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const ReadMore = styled(Link)`
  display: inline-block;
`;

const SearchField = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    flex: 0 0 80%;
  }

  button {
    flex: 1 1 20%;
  }
`;

const CardBody = styled.div`
  padding-left: 10px;
  padding-right: 10px;

  @media (min-width: 576px) {
    padding-left; 15px;
    padding-right: 15px;
  }
`;

const Column = styled.div`
  padding-left: 0;
  padding-right: 0;

  @media (min-width: 576px) {
    padding-left; 15px;
    padding-right: 15px;
  }
`;

const Form = styled.form`
  width: 100%;
`;

const indexPage = class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.userInputField = null;
  }
  onClickHandler(e) {
    e.preventDefault();

    const query = this.userInputField.value;
    if (query && query.length > 0) {
      navigate(`/items?query=${query}`);
    }
  }

  render() {
    const { data } = this.props;
    const gearTypes = ["main", "sub", "helmet", "chest", "gloves", "shoes"];

    const collections = data?.allContentfulGearCollection?.nodes;
    const gear = data?.allContentfulGear?.nodes;

    let gearSortedByType = {};
    gearTypes.forEach(type => {
      const sorted = gear.filter(item => item.type === type);
      gearSortedByType[type] = sorted;
    });

    const metaDescription = `Item Database & Wiki for MMO Black Desert Mobile. Full statistics and enhancement information.`;

    return (
      <>
        <Helmet
          title={`BDM.wiki | Item & Gear Database for Black Desert Mobile - Global`}
          description={metaDescription}
        >
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover"
          />

          <meta name="description" content={metaDescription} />

          <meta
            property="og:title"
            content={`BDM.wiki | Item & Gear Wiki for Black Desert Mobile - Global`}
          />
          <meta property="og:description" content={metaDescription} />
          <meta property="og:url" content="https://bdm.wiki" />
          <meta
            property="og:image"
            content="https://bdm.wiki/BDMwiki-logo.jpg"
          />
          <meta property="og:type" content="”website”" />
        </Helmet>

        <div className="container mt-3 mb-5">
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-12">
                  <WelcomeMessage />
                </div>
                <div className="col-12 col-md-6 col-lg-12">
                  <ServerTimes />
                </div>
              </div>

              <CategoryNavigation categories={collections} />
            </div>

            <div className="col-12 col-lg-8">
              <div className="row">
                <Column className="col-12">
                  <div className="card">
                    <h1 className="card-header h6">
                      Black Desert Mobile Item Search
                    </h1>
                    <CardBody className="card-body">
                      <SearchField>
                        <Form onSubmit={this.onClickHandler.bind(this)}>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search for an item"
                              aria-label="Search for an item"
                              aria-describedby="search-button"
                              ref={r => (this.userInputField = r)}
                            />
                            <div className="input-group-append">
                              <button
                                className="btn btn-dark"
                                type="submit"
                                id="search-button"
                              >
                                Search
                              </button>
                            </div>
                          </div>
                        </Form>
                      </SearchField>
                      <p>Most recently updated items:</p>
                      <ul
                        className="d-flex flex-wrap"
                        style={{ magin: 0, padding: 0, listStyle: "none" }}
                      >
                        {gear.map((g, i) => {
                          return <GearRow gear={g} key={i} />;
                        })}
                      </ul>
                    </CardBody>
                  </div>
                </Column>
              </div>
            </div>

            {false && (
              <div className="col-12">
                <div className="card">
                  {/* <h2 className="card-header h6">Black Desert Mobile News</h2> */}
                  <div className="row no-gutters">
                    <div className="col-4">
                      <VideoContainer className="card-img-top">
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/RytTGo7jF7M"
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </VideoContainer>
                    </div>

                    <div className="col-8">
                      <div className="card-body">
                        <h3 className="h6">
                          New World Boss: Enraged Red Nose!
                        </h3>
                        <p>
                          The recent patch this week has introduced a new World
                          Boss, but this one only occures once a week. Enraged
                          Red Nose appears every Tuesday from 20:00 to 22:00
                          server time, which means we now have another chance at
                          the Red Nose Armor drop.
                        </p>
                        <p>
                          Additionally, all the world bosses now drop Gear
                          Fragments and Red boss-specific items which you can
                          collect and use to craft Red items in your Blacksmith.{" "}
                          <ReadMore>Read More</ReadMore>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...state,
    ...ownProps,
  };
};

export default connect(mapStateToProps, {})(indexPage);

export const pageQuery = graphql`
  query HomePageQuery {
    allContentfulGearCollection {
      nodes {
        name
        baseSlug
        collection {
          name
        }
      }
    }
    allContentfulGear(sort: { order: DESC, fields: updatedAt }, limit: 10) {
      nodes {
        name
        type
        baseAp
        baseDp
        sockets
        weight
        updatedAt
        createdAt
        rarity
        subStats {
          name
          type
          amount
        }
        images {
          title
          description
          file {
            url
          }
          fluid(maxWidth: 500) {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
  }
`;
